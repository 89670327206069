import { useEffect } from 'react';
import {
  Box,
  Container,
  Divider,
  Link,
  List,
  Theme,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import Logo from '../components/layout/Logo';
import StickyFooterLayout from '../components/layout/StickyFooterLayout';
import CallUs from '../constants/linksList/CallUs';
import EmailUs from '../constants/linksList/EmailUs';
import TryAgain from '../constants/linksList/TryAgain';
import pageTitleConstants from '../constants/pageTitles';
import { PageWithLayout } from '../types/PageWithLayout';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(4),
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(0),
  },
  logo: {
    marginBottom: 20,
    marginTop: theme.spacing(2),
  },
  headline: {
    paddingBottom: theme.spacing(2.5),
  },
  listItems: {
    marginTop: theme.spacing(1),
  },
}));

const Error: PageWithLayout = () => {
  const classes = useStyles();

  useEffect(() => {
    document.title = `Oops! ${pageTitleConstants.suffix}`;
  }, []);

  return (
    <StickyFooterLayout HeaderElement={null}>

      <Box className={classes.logo}>
        <Link href={'/'} underline='hover'> <Logo size='xl' /></Link>
      </Box>
      <Container className={classes.container} maxWidth='sm'>

        <Box className={classes.headline}>
          <Typography align='center' aria-hidden='true' variant='h1'>
            🤔 Hmmm...
          </Typography>
          <Divider />
        </Box>
        <Typography variant='h2'>
          There seems to be an issue with your request
        </Typography>
        <Container className={classes.listItems} maxWidth='xs'>

          <Typography align='center' variant='body1'>
            Sorry about that. We are working to fix this error. Here are some troubleshooting tips:
          </Typography>
          <List>
            <TryAgain />
            <EmailUs />
            <CallUs />
          </List>
        </Container>

      </Container>
    </StickyFooterLayout>
  );
};

export default Error;
