import { FunctionComponent } from 'react';
import ReplayIcon from '@mui/icons-material/Replay';
import {
  Link,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';

import routes from '../../lib/routes';

const TryAgain: FunctionComponent = () => {
  return (
    <ListItem>
      <ListItemIcon>
        <ReplayIcon />
      </ListItemIcon>
      <ListItemText
        primary={<Link href={routes.internal.welcome.url} underline='hover'> Return to the form and try again</Link>}
        secondary='This may be a temporary network issue.'
      >
      </ListItemText>
    </ListItem>
  );
};

export default TryAgain;
